import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { P, SizingDemo, SpacingDemo, OpacityDemo, RadiusDemo, ShadowDemo, BreakpointDemo } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "прочие-токены"
    }}>{`Прочие токены`}</h1>
    <P mdxType="P">
  Помимо токенов цветов и текстовых стилей дизайн-система включает в себя токены
  для стандартных размеров элементов, стандартных отступов, прозрачности и
  закруглений углов элементов. Для каждого токена ниже приведены имя
  соответствующей SCSS-переменной и ее значение.
    </P>
    <h2 {...{
      "id": "стандартные-размеры-элементов-sizing"
    }}>{`Стандартные размеры элементов (Sizing)`}</h2>
    <SizingDemo mdxType="SizingDemo" />
    <h2 {...{
      "id": "стандартные-отступы-spacing"
    }}>{`Стандартные отступы (Spacing)`}</h2>
    <SpacingDemo mdxType="SpacingDemo" />
    <h2 {...{
      "id": "закругления-radius"
    }}>{`Закругления (Radius)`}</h2>
    <RadiusDemo mdxType="RadiusDemo" />
    <h2 {...{
      "id": "тени-shadow"
    }}>{`Тени (Shadow)`}</h2>
    <ShadowDemo mdxType="ShadowDemo" />
    <h2 {...{
      "id": "брейкпоинты-breakpoint"
    }}>{`Брейкпоинты (Breakpoint)`}</h2>
    <BreakpointDemo mdxType="BreakpointDemo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      